import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import ScrollMemory from 'react-router-scroll-memory'

import {
  Route,
  Switch,
  withRouter,
  Link
} from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'
import Header from 'components/Header'
import MainMenu from 'components/MainMenu'
import Footer from 'components/Footer'

const NotFoundPage = lazy(() => import('components/NotFoundPage'))
const ServerErrorPage = lazy(() => import('components/ServerErrorPage'))

const ItemsPage = lazy(() => import('components/skupka/items/ItemsPage'))
const ItemShowPage = lazy(() => import('components/items/ItemShowPage'))

import PageMeta from 'components/PageMeta'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
ReactGA.initialize('UA-113546551-1')
ReactPixel.init('1260276627467543', {}, { autoConfig: true, debug: false })
import ym, {YMInitializer} from 'react-yandex-metrika'
import currentUrl from 'utils/currentUrl'

// ----------------------------------------------------------------------------

class SkupkaApp extends Component {

  constructor(props) {
    super(props)
    if (props.session.user.id) {
      ReactGA.set({ userId: props.session.user.id, userType: props.session.user.type })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.user.id) {
      ReactGA.set({ userId: nextProps.session.user.id, userType: nextProps.session.user.type })
      ym('userParams', {
        UserType: nextProps.session.user.type,
        UserID: nextProps.session.user.id
      });
    } else {
      ReactGA.set({ userId: null, userType: null })
      ym('userParams', {
        UserType: undefined,
        UserID: undefined
      })
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      Object.defineProperty(document, "referrer", {
        value: (BACKEND_HOST + this.props.location.pathname).trim(),
        configurable: true
      })
    }
  }

  render() {
    const { user, isAuthenticated } = this.props.session
    const title = 'Скупка на Росагродел — сделки по скупке будущего урожая'
    const description = 'Больше не нужно искать покупателей. Продайте свой будущий урожай с предоплатой в 10%.'

    const logPageView = () => {
      var path = window.location.pathname
      // setTimeout(function() { ahoy.trackView() }, 1000)
      ReactGA.set({ page: path })
      ReactGA.pageview(window.location.pathname + window.location.search)
      ReactPixel.pageView()
      return null
    }

    return (
      <React.Fragment>
        <ScrollMemory />

        <div className="b-wrapper">

          <PageMeta
            title={ title }
            description={ description }
            url={ currentUrl() }
            image_url={ require('images/fblogo.png') }
          />

          <Route component={logPageView} />
          <YMInitializer accounts={[47572006]} options={{clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, userParams: {UserID: user.id, UserType: user.type}}} />

          <header className="b-header skupka">
            <Header />
            <MainMenu />
          </header>

          <main className="b-content">
            <Suspense fallback={
              <div className="center">
                { _t('loading') }
              </div>
            }>
              <Switch>
                <Route exact path='/' component={ItemsPage} />
                <Route path='/items/:itemId(\d+)' component={ItemShowPage} />
                <Route path='/items/:itemId(\d+)-:sefTitle' component={ItemShowPage} />
                <Route path='/server_error' component={ServerErrorPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </main>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    session: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(SkupkaApp))
