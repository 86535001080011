import React from "react"
import ReactDOM from "react-dom"
import SkupkaContainer from '../components/SkupkaContainer'

import '../styles/bootstrap.css'
import '../styles/justified-nav.css'
import '../styles/style.css'

import _ from 'lodash'
import ahoy from "ahoy.js"
import axios from 'utils/axios'
import cachios from 'utils/cachios'
import i18n from 'utils/i18n'
import { getCurrentUser } from 'api/sessions'
import { setUserLocale } from 'utils/user_locale_storage'

// ahoy.configure({
//   urlPrefix: "",
//   visitsUrl: "/ahoy/visits",
//   eventsUrl: "/ahoy/events",
//   page: null,
//   platform: "Web",
//   useBeacon: true,
//   startOnReady: true,
//   trackVisits: true,
//   cookies: true,
//   cookieDomain: null,
//   headers: {},
//   visitParams: {},
//   withCredentials: false
// });

global._ = _
// global.ahoy = ahoy
global.axios = axios
global.cachios = cachios
global.i18n = i18n
global._t = (key, params) => {
  return i18n.t(key, params)
}

global.app_type = 'skupka'

i18n.on('languageChanged', lng => {
  setUserLocale(lng)
  axios.defaults.headers.common['Locale'] = lng
  const rootElement = document.getElementById("root")
  ReactDOM.unmountComponentAtNode(rootElement)

  getCurrentUser()
    .then(res => {
      ReactDOM.render(<SkupkaContainer user={ res.data.data.user } />, rootElement)
    })
    .catch(err => {
      ReactDOM.render(<SkupkaContainer user={ null } />, rootElement)
    })
})
